<template>
  <div>
    <h2>Statistica asseverazioni</h2>
    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
    <v-row class="mt-5">
      <v-col v-for="item in statisticaEnti" :key="item.tipologia">
        <statistica-enti :statistica="item" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import StatisticaEnti from '../elementi/statisticaEnti'
  import selfapi from '@/services/selfapi.js'

  export default {
    components: {
      StatisticaEnti
    },
    data () {
      return {
        totaleEnti: 0,
        loading: false,
        filtri: null,
        statisticaEnti: [],
        tipologie: ['Scuole', 'Comuni'],
        periodi: []
      }
    },
    async mounted () {
      this.periodi = []
      for (let tipo of this.tipologie) {
        for (let p = 0; p < 3; p++) {
          let filtro = {
            tipologia: tipo,
            end: null
          }
          switch(p) {
            case 0:
              filtro.start = moment().utcOffset(0, true).add(-7, 'days').startOf('day').format('YYYY-MM-DD')
              break
            case 1:
              filtro.start = moment().utcOffset(0, true).add(-30, 'days').startOf('day').format('YYYY-MM-DD')
              break
            default:
              filtro.start = null
          }        
          this.periodi.push(filtro)
        }
      }
      await this.getDataFromApi()
    },
    methods: {
      async onRicerca(filtri) {
        this.filtri = filtri
        await this.getDataFromApi()
      },
      async getDataFromApi () {
        try {
          this.loading = true
          this.statisticaEnti = await selfapi.getStatisticaEnti()
          this.statisticaEnti = this.statisticaEnti.filter(x => ['Scuole', 'Comuni'].includes(x.tipologia))
        } catch(err) {
        } finally {
          this.loading = false
        }
      }
    }    
  }
</script>